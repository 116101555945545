// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    /* color: #fff; */
    font-family: 'Open Sans', sans-serif;
}

:root{
    /* text colors */
    --t-pink:#D22F7D;
    --t-white:#ffffff;
    --t-black:#191617;
    --t-lgrey:#969696;
    
    /* background colors */
    --bg-dpink:#D22F7D;
    --bg-lgrey:#F2F2F2;
    --bg-pink:#E6007E;
    --bg-lpink:#C93374C4;
    --bg-black:#000000;
    --bg-white:#FFFFFF;
    --bg-grey:#757070;
    --box_shadow:rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


body{
    margin: 0;
    padding: 0;
}

button{
    border: solid 0.5px rgba(255,255,255,0.5);
    background-color: transparent;
    color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 15px 10px 10px 10px;
    cursor: pointer;
}

button:hover{
    background-color: #343541;
}

.app{
    display: flex;
    background-color: #343541;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;;IAEjB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,kDAAkD;AACtD;;;AAGA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,yCAAyC;IACzC,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');\n\n*{\n    /* color: #fff; */\n    font-family: 'Open Sans', sans-serif;\n}\n\n:root{\n    /* text colors */\n    --t-pink:#D22F7D;\n    --t-white:#ffffff;\n    --t-black:#191617;\n    --t-lgrey:#969696;\n    \n    /* background colors */\n    --bg-dpink:#D22F7D;\n    --bg-lgrey:#F2F2F2;\n    --bg-pink:#E6007E;\n    --bg-lpink:#C93374C4;\n    --bg-black:#000000;\n    --bg-white:#FFFFFF;\n    --bg-grey:#757070;\n    --box_shadow:rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n\n\nbody{\n    margin: 0;\n    padding: 0;\n}\n\nbutton{\n    border: solid 0.5px rgba(255,255,255,0.5);\n    background-color: transparent;\n    color: white;\n    border-radius: 5px;\n    padding: 10px;\n    margin: 15px 10px 10px 10px;\n    cursor: pointer;\n}\n\nbutton:hover{\n    background-color: #343541;\n}\n\n.app{\n    display: flex;\n    background-color: #343541;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
