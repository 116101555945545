import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Chatbot from './Pages/Chatbot/Chatbot';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import SubscriptionPage from './Pages/Subscription/Subscription';
import PaymentSuccess from './Pages/PaymentSuccess/PaymentSuccess'
import PaymentFailed from './Pages/PaymentFailed/PaymentFailed';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import EmailSent from './Pages/EmailSent/EmailSent';

function App() {
  // const { user } = useSelector((store) => store.auth);

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/register" />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/payment-Successful" element={<PaymentSuccess/>} />
          <Route path="/payment-Cancelled" element={<PaymentFailed/>} />
          <Route path="/" element={ <Chatbot /> }/>
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}


export default App;
