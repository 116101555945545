import '../Register/Register.css'
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import {resetPassword, reset} from '../../features/authSlice/authSlice'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [formData, setFormData] = useState({
        password:'',
        confirmPassword:''
    })
    const {password,confirmPassword} = formData
    const { user,isSuccess,isLoading,isError,message }=useSelector((store)=>store.auth)

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const onChange =(e)=>{
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }
 
    const onSubmit =(e)=>{
        e.preventDefault()
        if(password !== confirmPassword){
            toast.error('passwords do not match')
        }else{
            const userData = {password,token}
            dispatch(resetPassword(userData))
        }
    }

    useEffect(()=>{
        if(isSuccess){
            navigate('/login')
        }
        dispatch(reset())

    },[user,isSuccess,isLoading,isError,message,navigate,dispatch])


return (
    <>
    <Header/>
    <div className='login_Page'>
        <div className='Form_LoginPage'>
            <h2>RESET YOUR PASSWORD</h2>
            <form onSubmit={onSubmit}>
                <div className='form-groupDashboard'>
                    <input type='password' id='password' name='password' value={password} placeholder='Enter password' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <input type='password' id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Password' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <button className='btnDashboard' type='submit'>Submit</button>
                </div>
                <a href='/forgot-password' className='btnDashboard' type='submit'>Resend Link</a>
            </form>
        </div> 
    </div>        
    <Footer/>
    </>
  )
}

export default ResetPassword
