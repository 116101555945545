import { Provider } from 'react-redux';
import { store } from './app/store'; // Import your store and persistor
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);


