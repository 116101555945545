// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successDiv{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 8rem 0; */
    background: #EBF0F5;
    height: 100vh;
}
i{
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
}
.card {
    background: white;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}
@media screen and (max-width: 600px){
    .successDiv{
        padding:0rem 80px ;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/PaymentSuccess/PaymentSuccess.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,6BAA6B;IAC7B,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".successDiv{\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    /* padding: 8rem 0; */\n    background: #EBF0F5;\n    height: 100vh;\n}\ni{\n    color: #9ABC66;\n    font-size: 100px;\n    line-height: 200px;\n    margin-left:-15px;\n}\n.card {\n    background: white;\n    padding: 40px;\n    border-radius: 4px;\n    box-shadow: 0 2px 3px #C8D0D8;\n    display: inline-block;\n    margin: 0 auto;\n}\n@media screen and (max-width: 600px){\n    .successDiv{\n        padding:0rem 80px ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
