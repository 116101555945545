import React, { useState } from 'react';
import './MobileNavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTitle, setMessage, setValue, reset } from '../../features/ChatSlice/ChatSlice';
import { logout, resetAll } from '../../features/authSlice/authSlice';
import Sidebar from '../Sidebar/Sidebar'; // Import the Sidebar component

const MobileNav = ({ url }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.auth);
  const currentPage = window.location.pathname;
  const shouldShowBurger = currentPage.includes('/');

  const { allChatDB, previousChats, currentTitle, message, value } = useSelector((state) => state.chats);

  let uniqueTitles = Array.from(new Set(previousChats.map(previousChat => previousChat.title)));

  const [show, setShow] = useState(false); // State to track whether the hamburger menu is open
  const [firstShow, setFirstShow] = useState(true);

  const showFunction = () => {
    setFirstShow(false);
    setShow(!show); // Toggle the state when the hamburger is clicked
  };
  
  const createNewChat = () => {
    dispatch(setMessage(null));
    dispatch(setValue(''));
    dispatch(setCurrentTitle(''));
  };

  const handleClick = (uniqueTitle) => {
    dispatch(setCurrentTitle(uniqueTitle));
    dispatch(setMessage(null));
    dispatch(setValue(''));
  };

  const onLogout = () => {
    dispatch(logout())
      .then(dispatch(reset()))
      .then(dispatch(resetAll()))
      .then(navigate('/login'));
  };

  return (
    <div className="Mob_Navbar">
      {shouldShowBurger && (
        <div className={show ? 'cross burger' : 'burger'} onClick={showFunction}>
          <div></div>
          <div></div>
        </div>
      )}

      {/* Conditionally render the Sidebar based on the show state */}
      {show ? (
        <div className='mobile_nav'>
        <div style={{ display:'flex', justifyContent:'right'}}>
            <button className='newChat_btn' onClick={createNewChat}>+ New Chat</button>
        </div>
        <ul className="history">
          {uniqueTitles?.reverse().map((uniqueTitle, index) => (
            <li className={uniqueTitle === currentTitle ? 'active' : ''} key={index} onClick={() => handleClick(uniqueTitle)}>
              {uniqueTitle}
            </li>
          ))}
        </ul>
        <nav>
          <p>Made by Fori.co</p>
          <button className='btn' onClick={onLogout}>
            Logout
          </button>
        </nav>
      </div>
    ) : ( null
      )}
    </div>
  );
};

export default MobileNav;
