import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import {forgotPassword, reset} from '../../features/authSlice/authSlice'
import Header from '../../Components/Header/Header';
import Footer from "../../Components/Footer/Footer";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email:''
    })
    const {email} = formData
    const { user,isSuccess,isLoading,isError,message }=useSelector((store)=>store.auth)

    const onChange =(e)=>{
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }
 
    const onSubmit =(e)=>{
        e.preventDefault()
        const userData = {email}
        dispatch(forgotPassword(userData))
    }

    useEffect(()=>{
        if(isSuccess){
            navigate('/email-sent')
        }
        dispatch(reset())
    },[user, isError, isSuccess,message, dispatch, navigate])


return (
    <>
    <Header/>
    <div className='login_Page'>
        <div className="Form_LoginPage">
            <h2>ENTER YOUR EMAIL</h2>
            <form onSubmit={onSubmit}>
                <div className='form-groupDashboard'>
                    <input type='email' id='email' name='email' value={email} placeholder='Enter your email' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <button className='btnDashboard' type='submit'>Submit</button>
                </div>
                <p>Create your account by <a href='/register'> Sign Up</a> here.</p>
            </form>
        </div>
    </div>        
    <Footer/>
</>

  )
}

export default ForgotPassword
