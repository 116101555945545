import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'
import Main from '../../Components/Main/Main'

const Chatbot = () => {
  const navigate = useNavigate()
  const { user, isError, message }=useSelector((store)=>store.auth)
  useEffect(()=>{
    if(isError){
      console.log(message)
    }
    if(!user){
      navigate('/login')
    }
  },[user,navigate,isError,message])

  return (
    <div className='app'>
      <Sidebar/>
      <Main/>
    </div>
  )
}

export default Chatbot
