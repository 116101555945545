import React from 'react'
import './PaymentSuccess.css'

const PaymentSuccess = () => {
return (
<div className='successDiv'>
    <div className="card">
        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
            <i className="checkmark">✓</i>
        </div>
        <h1>Success</h1> 
        <p>Thankyou for completing your secure online payment.<br/> Go to Home Page!</p>
        <a style={{borderRadius:'5px',padding:'10px',fontWeight: 'bold', textDecoration: 'none', color: 'black',font:'bold', background: 'rgb(195, 198, 190)'} } href='/'>Home Page</a>
    </div>
</div>
)
}
export default PaymentSuccess