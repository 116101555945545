// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  } */
  
  .subscription-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* text-align: center; */
  }
  
  .subscription-cards {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    /* padding: 10rem; */

  }
  
  .subscription-card {
    border: 1px solid #ccc;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
  
  .price {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .features {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .subscribe-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .subscription-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .subscription-card {
      max-width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Subscription/Subscription.css"],"names":[],"mappings":"AAAA;;;;KAIK;;EAEH;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,4BAA4B;IAC5B,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,WAAW;IACX,oBAAoB;;EAEtB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB;;EAEA,mCAAmC;EACnC;IACE;MACE,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":["/* body {\n    font-family: Arial, sans-serif;\n    margin: 0;\n    padding: 0;\n  } */\n  \n  .subscription-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    /* text-align: center; */\n  }\n  \n  .subscription-cards {\n    display: flex;\n    /* flex-direction: column; */\n    align-items: center;\n    justify-content: center;\n    gap: 20px;\n    width: 100%;\n    /* padding: 10rem; */\n\n  }\n  \n  .subscription-card {\n    border: 1px solid #ccc;\n    padding: 20px;\n    width: 100%;\n    max-width: 300px;\n    text-align: center;\n  }\n  \n  .price {\n    font-size: 1.5rem;\n    margin-top: 10px;\n  }\n  \n  .features {\n    list-style-type: none;\n    padding: 0;\n    margin-top: 20px;\n  }\n  \n  .subscribe-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    margin-top: 20px;\n    cursor: pointer;\n  }\n  \n  /* Media Query for Mobile Devices */\n  @media (max-width: 768px) {\n    .subscription-cards {\n      flex-direction: column;\n      align-items: center;\n    }\n  \n    .subscription-card {\n      max-width: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
