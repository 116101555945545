import React from 'react'
import '../PaymentSuccess/PaymentSuccess.css'

const PaymentFailed = () => {
return (
<div className='successDiv'>
    <div className="card">
        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
            <i className="checkmark" style={{color:'red'}}>✖</i>
        </div>
        <h1>Error</h1> 
        <p>Unfortunately we have an issue with your payment.<br/> Please try again later!</p>
    </div>
</div>
)
}
export default PaymentFailed