import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {toast} from 'react-toastify'


/////get user from localStorage
const user =JSON.parse(localStorage.getItem('user'))

const initialState ={
    user: user ? user : null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:''
}

///////////Register User/////////
export const register = createAsyncThunk('authSlice/register',async(userData, thunkAPI)=>{
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/registerUser`;

        const response = await axios.post(API_URL,userData)
        if(response.data){
            localStorage.setItem('user',JSON.stringify(response.data))
        }
        return response.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


///////////Login User/////////
export const login = createAsyncThunk('authSlice/login',async(userData, thunkAPI)=>{
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/loginUser`;
        const response = await axios.post(API_URL,userData)
        if(response.data){
            localStorage.setItem('user',JSON.stringify(response.data))
        }
        return response.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//////logout
export const  logout = createAsyncThunk('auth/logout',async ()=>{
    localStorage.removeItem('user')
})


///////////Forgot Password/////////
export const forgotPassword = createAsyncThunk('authSlice/forgotPassword',async(userData, thunkAPI)=>{
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/forgotPassword`;
        const response = await axios.post(API_URL,userData)
        return response.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



///////////Reset Password/////////
export const resetPassword = createAsyncThunk('authSlice/resetPassword',async(userData, thunkAPI)=>{
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/resetPassword`;
        const response = await axios.post(API_URL,userData)
        return response.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})




export const authSlice =createSlice({
    name:"auth",
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ""
        },
        resetAll:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.user = null
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(register.pending,(state)=>{
            state.isLoading = true
        })
        .addCase(register.fulfilled,(state , action)=>{
            if(action.payload.success===true){
                state.user = action.payload
            }
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
        })        
        .addCase(register.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null

        })

        .addCase(login.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(login.fulfilled, (state, action)=>{

            if(action.payload.success===true){
                state.user = action.payload
            }
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(login.rejected,(state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(logout.fulfilled,(state)=>{
            state.isLoading = false
            state.isError = false
            state.message = ''
            state.user = null
        })
        .addCase(forgotPassword.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(forgotPassword.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            toast.info(action.payload.message)
        })
        .addCase(forgotPassword.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            toast.info(action.payload)

        })
        .addCase(resetPassword.pending, (state, action)=>{
            state.isLoading = true
        })
        .addCase(resetPassword.fulfilled, (state, action)=>{
            state.message = action.payload.message
            toast.info(action.payload.message)
            state.isLoading= false
            state.isSuccess = true
        })
        .addCase(resetPassword.rejected, (state, action)=>{
            state.isError = true
            state.isLoading = false
            toast.error(action.payload)
        })
    }
})

export const {reset,resetAll} = authSlice.actions
export default authSlice.reducer