import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    previousChats: [],
    currentChat:[],
    allChatDB:[],
    currentTitle: null,
    message: '',
    value: '',
    counter: 0,
    url:"",
    isLoading: false,
    isSuccess: false,
    isError: false,
  };

 ///////////////////completion///////////////////
export const completions = createAsyncThunk('chats/completions', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const { value } = state.chats;
  const token = thunkAPI.getState().auth.user.token
  const options = {
    method: 'POST',
    body: JSON.stringify({
      message: value,
    }),
    headers:{
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatbot/completions`, options);
    const data = await response.json();
    return {
      // counter: data.counter,
      message: data.data.choices[0].message
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
});


/////////////getAllChats////////////////
export const getAllChats = createAsyncThunk('chats/getAllChats', async (_, thunkAPI) => {

  const state = thunkAPI.getState();
  const token = state.auth.user.token;
  const currentChat = state.chats.currentChat; // Get the previousChats from your Redux store
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/getAllChats`;
    const response = await axios.post(API_URL, { currentChat }, config); // Include previousChats in the request body    
    const allData = await response.data
    return {
      previousChats: allData.existingChat.previousChats,
      counter: allData.existingChat.counter,
    };
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


/////////subscription////////////////
export const subscription =  createAsyncThunk('chats/subscription', async (price_id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token
  const config ={
     headers:{
         Authorization: `Bearer ${token}`
     }
 }
  try {
    const API_URL = `${process.env.REACT_APP_SERVER_URL}/chatbot/subscription`;

    const response = await axios.post(API_URL, price_id,config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
}
});

export const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    reset: () => initialState,
    setCurrentTitle: (state, action) => {
      state.currentTitle = action.payload;
    },
    addChat: (state, action) => {
      state.currentChat = [action.payload, ...state.currentChat.slice(0, 1)];
      state.previousChats.push(action.payload);
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setValue: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(completions.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(completions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.counter = action.payload.counter
        state.message = action.payload; // Update the message state
      })
      .addCase(completions.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getAllChats.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getAllChats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.counter = action.payload.counter
        state.previousChats = action.payload.previousChats; // Update the message state
      })
      .addCase(getAllChats.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(subscription.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(subscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.url = action.payload.url
        if(action.payload.message==="success"){
          window.location.replace(state.url)
        }      
      })
      .addCase(subscription.rejected, (state,action) => {
        state.isLoading = false;
        state.isError = true;
        state.url = action.payload.url
        if(action.payload.message==="error"){
          window.location.replace(state.url)
        }      
      });
    },
});

export const { reset, setCurrentTitle, addChat, setMessage, setValue } = chatSlice.actions;
export default chatSlice.reducer;