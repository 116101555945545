import './Sidebar.css'
import {Link, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTitle, setMessage, setValue,reset } from '../../features/ChatSlice/ChatSlice';
import {logout,resetAll} from '../../features/authSlice/authSlice'

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user }=useSelector((store)=>store.auth)


  const { allChatDB,previousChats, currentTitle, message, value } = useSelector((state) => state.chats);

  let uniqueTitles = Array.from(new Set(previousChats.map(previousChat => previousChat.title)))

  const createNewChat =()=>{
    dispatch(setMessage(null))
    dispatch(setValue(''))
    dispatch(setCurrentTitle(''))
  }

  const handleClick = (uniqueTitle)=>{
    dispatch(setCurrentTitle(uniqueTitle))
    dispatch(setMessage(null))
    dispatch(setValue(''))
  }

  const onLogout = ()=>{
    dispatch(logout())
    .then(dispatch(reset()))
    .then(dispatch(resetAll()))
    .then(navigate('/login'))
  }


  return (
    <div className='side_bar'>
      <button onClick={createNewChat}>+ New Chat</button>
      <ul className="history">
        {uniqueTitles?.reverse().map((uniqueTitle,index) => <li className={uniqueTitle === currentTitle ? 'active' : ''} key={index} onClick={()=>handleClick(uniqueTitle)}>{uniqueTitle}</li>)}
      </ul>
      <nav>
        <p>Made by Fori.co</p>
        <button className='btn' onClick={onLogout}>Logout</button>
      </nav>
    </div>
  )
}
export default Sidebar