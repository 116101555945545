import React, { useEffect, useState, useRef } from 'react';
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import './Main.css';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTitle, addChat, setMessage, setValue, completions, getAllChats } from '../../features/ChatSlice/ChatSlice';
import MobileNav from '../MobileNavbar/MobileNavbar';

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { previousChats, currentTitle, value, counter, message, isSuccess, isError } = useSelector((state) => state.chats);

  const currentTabChat = previousChats.filter(previousChat => previousChat.title === currentTitle);
  const [isLoading, setIsLoading] = useState(false);

  const chatContainerRef = useRef(); // Create a ref for the chat container

  const onSubmit = (e) => {
    e.preventDefault();
    if(isError){
      navigate('/login')
      toast('Session Expired, please login again to continue')
    }
    if (value.trim() !== '') {
      if (value !== currentTitle) {
        setIsLoading(true);
        dispatch(completions());
      }
    }
  };

  useEffect(() => {
    dispatch(getAllChats());
  }, []);

  useEffect(() => {
    if (message && value && !currentTitle) {
      dispatch(setCurrentTitle(value));
    }
    if (message && value && currentTitle) {
      setIsLoading(false);
      dispatch(addChat({ title: currentTitle, role: 'user', content: value }));
      dispatch(addChat({ title: currentTitle, role: 'AI', content: message }));
      dispatch(getAllChats());
      dispatch(setValue(''));
    }
  }, [dispatch, message, currentTitle]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when the component updates
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  });

  return (
    <>
      <MobileNav />

      <div className="Main">
        <h1>ChatBot</h1>
        <ul ref={chatContainerRef} className="feed">
          {currentTabChat.map((chat, index) => (
            <li key={index} className={`chat-item ${chat.role === 'user' ? 'user-chat' : 'ai-chat'}`}>
              {chat.role === 'AI' ? (chat.content.message ? chat.content.message.content : chat.content.content) : chat.content}
            </li>
          ))}

          {isLoading && counter !== 5 && <li className="">Typing...</li>}
        </ul>

        <div className="bottom_section">
          <form onSubmit={onSubmit} className="chat-input-container">
            <input
              placeholder="Send a message"
              className="chat-input-field"
              value={value}
              onChange={(e) => dispatch(setValue(e.target.value))}
            />
            <button type="submit" className="chat-submit-button">
              ⮚
            </button>
          </form>

          <p className="info">
            Free Research Preview. ChatBot may produce inaccurate information about people, places, or facts.
          </p>
        </div>

        {counter === 5 && isSuccess === false && (
          <p>
            You have reached the subscription limit. Please subscribe to continue.
            <a className="aTagSub" href="/subscription">
              Subscribe Now
            </a>
          </p>
        )}
      </div>
    </>
  );
};
export default Main;
