// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side_bar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #202123;
    height: 100vh;
    width: 15%;
}

nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: solid 0.5px rgba(255,255,255,0.5);
    padding: 10px;
    margin: 10px;
}

.history{
    padding: 10px;
    /* margin: 10px; */
    height: 100%;
    overflow-y: auto;
}
.history:hover{
    scroll-behavior: smooth;
}

.history li{
    overflow: hidden;
    list-style-type: none;
    padding:5px 5px 7px 5px;
    cursor: pointer;
    width: 94%;
    height: 2.5vh;
}
.history li.active {
    background-color: #444;
    color: white;
    border-radius: 5px;
    padding:5px 5px 7px 5px;
    width: 94%;
  }
@media screen and (max-width: 1200px){
    .side_bar{
        width: 20%;
    }
}
@media screen and (max-width: 900px){
    .side_bar{
        width: 30%;
    }
}
@media screen and (max-width: 600px){
    .side_bar{
        width: 30%;
    }
}
@media only screen and (max-width: 550px) {
    .side_bar{
        display: none;
    }
    .side_bar>*{
        display: none;
    }
}


`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6CAA6C;IAC7C,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;IACf,UAAU;IACV,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,UAAU;EACZ;AACF;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".side_bar{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background-color: #202123;\n    height: 100vh;\n    width: 15%;\n}\n\nnav{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-top: solid 0.5px rgba(255,255,255,0.5);\n    padding: 10px;\n    margin: 10px;\n}\n\n.history{\n    padding: 10px;\n    /* margin: 10px; */\n    height: 100%;\n    overflow-y: auto;\n}\n.history:hover{\n    scroll-behavior: smooth;\n}\n\n.history li{\n    overflow: hidden;\n    list-style-type: none;\n    padding:5px 5px 7px 5px;\n    cursor: pointer;\n    width: 94%;\n    height: 2.5vh;\n}\n.history li.active {\n    background-color: #444;\n    color: white;\n    border-radius: 5px;\n    padding:5px 5px 7px 5px;\n    width: 94%;\n  }\n@media screen and (max-width: 1200px){\n    .side_bar{\n        width: 20%;\n    }\n}\n@media screen and (max-width: 900px){\n    .side_bar{\n        width: 30%;\n    }\n}\n@media screen and (max-width: 600px){\n    .side_bar{\n        width: 30%;\n    }\n}\n@media only screen and (max-width: 550px) {\n    .side_bar{\n        display: none;\n    }\n    .side_bar>*{\n        display: none;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
