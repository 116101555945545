// store.js
import { configureStore } from '@reduxjs/toolkit';
import chatSliceReducer from '../features/ChatSlice/ChatSlice';
import authSliceReducer from '../features/authSlice/authSlice';

export const store = configureStore({
  reducer:{
    chats: chatSliceReducer,
    auth: authSliceReducer,
  }
});