import './Footer.css'
import {Link} from 'react-router-dom'
import {BsInstagram,BsLinkedin} from "react-icons/bs";
import {MdMail} from "react-icons/md";

const Footer = () => {
  return (
    <div className='footer'>
        <div className='flex_row_sb footer_top'>
            <div className='flex_row_sb'>
                <div className='flex_row_c footer_top_left'>
                    <a className="icons-a" href="https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ=="><BsInstagram className="icons-circle"/></a>
                    <a className="icons-a" href="https://www.linkedin.com/company/foriinc/"><BsLinkedin className="icons-circle"/></a>
                    <a className="icons-a" href="mailto:ceo@fori.co"><MdMail className="icons-circle"/></a>
                </div>
                <div className='flex_row_sb footer_top_right'>
                    <div className='linkFooter'><small className='linkFooter'>2023 © Copyrights Fori Inc. All Rights Reserved</small></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
