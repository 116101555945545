import './Register.css'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import {register, reset} from '../../features/authSlice/authSlice'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';

const Register = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        password:'',
        confirmPassword:''
    })
    const {name,email,password,confirmPassword} = formData
    const { user,isSuccess,isLoading,isError,message }=useSelector((store)=>store.auth)

    const onChange =(e)=>{
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }
 
    const onSubmit =(e)=>{
        e.preventDefault()
        if(password !== confirmPassword){
            toast.error('passwords do not match')
        }else{
            const userData = {name,email,password,confirmPassword}
            dispatch(register(userData))
        }
    }

    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess){
            toast.success(message)
            navigate('/')
        }
        dispatch(reset())

    },[user,isSuccess,isLoading,isError,message,navigate,dispatch])


return (
    <>
    <Header/>
    <div className='login_Page'>
        <div className='Form_LoginPage'>
            <h2>SIGN UP HERE</h2>
            <form onSubmit={onSubmit}>
                <div className='form-groupDashboard'>
                    <input type='text' id='name' name='name' value={name} placeholder='Enter your name' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <input type='email' id='email' name='email' value={email} placeholder='Enter your email' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <input type='password' id='password' name='password' value={password} placeholder='Enter password' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <input type='password' id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Password' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <button className='btnDashboard' type='submit'>Create Account</button>
                </div>
                <p>Already have an account?<a href='/login'> Sign In</a> here.</p>
            </form>
        </div> 
    </div>        
    <Footer/>
    </>
  )
}

export default Register
