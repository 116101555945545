import React from 'react';
import './Subscription.css';
import { useDispatch } from 'react-redux';
import { subscription } from '../../features/ChatSlice/ChatSlice'
import { useState } from 'react';

const SubscriptionPage = () => {
  const dispatch = useDispatch()

  const subscribe = async ({price_id}) => {
    try {
      dispatch(subscription({price_id}));
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };


  return (
    <div className="subscription-page">
      <h1>Subscription Plans</h1>
      <div className="subscription-cards">
        <div className="subscription-card">
          <h2>Monthly Plan</h2>
          <p>0.0015$ / Api call</p>
          <button className="subscribe-button" onClick={() => subscribe({price_id: process.env.REACT_APP_STRIPE_PRICE_ID})}>
            Subscribe Monthly
          </button>
        </div>
      </div>
    </div>

  );
};

export default SubscriptionPage;
