import "./Header.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Header = () => {
  
  return (

<div className='Bannerheader'>
    <div className="header_left">
        <a href="https://fori.co"><img src={require('../../Assets/Final-Logo.png')} alt="" className=" animate-element"/></a>
    </div>
    <div className="header_right animate-element">
      <Link to="/">CHATBOT</Link>
    </div>

</div>
    
  )
}

export default Header;