import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import {login, reset} from '../../features/authSlice/authSlice'
import Header from '../../Components/Header/Header';
import Footer from "../../Components/Footer/Footer";

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email:'',
        password:'',
    })
    const {email,password} = formData
    const { user,isSuccess,isLoading,isError,message }=useSelector((store)=>store.auth)

    const onChange =(e)=>{
        setFormData((prevState)=>({
            ...prevState,
            [e.target.name]:e.target.value
        }))
    }
 
    const onSubmit =(e)=>{
        e.preventDefault()
        const userData = {email,password}
        dispatch(login(userData))
    }

    useEffect(()=>{
        if(isError){
            toast.error(message)
        }
        if(isSuccess){
            navigate('/')
        }
        dispatch(reset())
    },[user, isError, isSuccess,message, dispatch, navigate])


return (
    <>
    <Header/>
    <div className='login_Page'>
        <div className="Form_LoginPage">
            <h2>SIGN IN HERE</h2>
            <form onSubmit={onSubmit}>
                <div className='form-groupDashboard'>
                    <input type='email' id='email' name='email' value={email} placeholder='Enter your email' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <input type='password' id='password' name='password' value={password} placeholder='Enter password' onChange={onChange}/>
                </div>
                <div className='form-groupDashboard'>
                    <button className='btnDashboard' type='submit'>Login</button>
                </div>
                <a style={{textDecoration:'underline', fontSize:'medium'}} href='/forgot-password'>Forgot Password?</a>
                <p>Create your account by <a href='/register'> Sign Up</a> here.</p>
            </form>
        </div>
    </div>        
    <Footer/>
</>

  )
}

export default Login
