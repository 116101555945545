import '../PaymentSuccess/PaymentSuccess.css'
import { CiMail } from "react-icons/ci";


const EmailSent = () => {
return (
<div className='successDiv'>
    <div className="card">
        <div style={{ display:'flex', alignItems:'center' , justifyContent:'center', borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
            <CiMail className="checkmark" style={{height: '5rem', width: '5rem'}} />
        </div>
        <h3>Check your email</h3> 
        <p>Please check your email for instructions to reset your password.</p>
        <a style={{borderRadius:'5px',padding:'10px',fontWeight: 'bold', textDecoration: 'none', color: 'black',font:'bold', background: 'rgb(195, 198, 190)'} } href='/'>Home Page</a>
    </div>
</div>
)}
export default EmailSent